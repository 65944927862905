import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useRouter } from 'src/routes/hooks';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { Box, Typography, Button, CircularProgress } from '@mui/material';

export default function VerifyEmail() {
const { token } = useParams()
  const router = useRouter();
  const [verificationStatus, setVerificationStatus] = useState('loading'); // loading, success, error
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const verifyEmail = async () => {
      
      if (!token) {
        setVerificationStatus('error');
        setErrorMessage('No verification token provided');
        return;
      }

      console.log(token)
      try {
        const response = await fetch('https://api.octaship.com/api/verify_email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ref: token }),
        });

        const data = await response.json();

        if (data.status_code === 200) {
          setVerificationStatus('success');
        } else {
          setVerificationStatus('error');
          setErrorMessage(data.status_desc || 'Verification failed');
        }
      } catch (error) {
        setVerificationStatus('error');
        setErrorMessage('Network error occurred. Please try again later.');
      }
    };

    verifyEmail();
  }, [token]);

  const renderContent = () => {
    switch (verificationStatus) {
      case 'loading':
        return (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress size={60} sx={{ color: 'primary.main', mb: 3 }} />
            <Typography variant="h5">Verifying your email...</Typography>
            <Typography color="text.secondary" sx={{ mt: 1 }}>
              Please wait while we verify your email address
            </Typography>
          </Box>
        );

      case 'success':
        return (
          <Box sx={{ textAlign: 'center' }}>
            <FaCheckCircle size={60} color="#2196f3" style={{ marginBottom: '24px' }} />
            <Typography variant="h5" sx={{ mb: 2 }}>
              Email Verified Successfully!
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 4 }}>
              Your email has been verified. You can now login to your account.
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => router.push('/auth/login')}
              sx={{
                bgcolor: 'primary.main',
                '&:hover': { bgcolor: 'primary.dark' },
              }}
            >
              Go to Login
            </Button>
          </Box>
        );

      case 'error':
        return (
          <Box sx={{ textAlign: 'center' }}>
            <FaExclamationCircle size={60} color="#f44336" style={{ margin: "auto", marginBottom: '24px' }} />
            <Typography variant="h5" sx={{ mb: 2 }}>
              Verification Failed
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 4 }}>
              {errorMessage}
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => router.push('/auth/login')}
              sx={{
                bgcolor: 'primary.main',
                '&:hover': { bgcolor: 'primary.dark' },
              }}
            >
              Back to Login
            </Button>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        bgcolor: 'background.default',
      }}
    >
      <Box
        sx={{
          maxWidth: 480,
          width: '100%',
          bgcolor: 'background.paper',
          borderRadius: 2,
          p: 4,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        }}
      >
        {renderContent()}
      </Box>
    </Box>
  );
}
